<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" offset-sm="0">
        <v-card id="listcard" :loading="loading">
          <h2 v-if="!!!itemList">Keine Templates gefunden</h2>
          <v-data-table
            :headers="headers"
            :items="itemList"
            :search="search"
            :sort-by="['type']"
            :sort-desc="[true]"
            :itemsPerPage="-1"
            multi-sort
          >
            <template slot="item.type" slot-scope="{ item }">
              <span>{{ itemType(item.type) }}</span>
            </template>

            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Suche"
                class="pa-4"
                :outlined="true"
                :filled="true"
                :clearable="true"
                background-color="#e8eff7"
              ></v-text-field>
            </template>
            <template slot="item.active" slot-scope="{ item }">
              <v-chip small :color="item.active ? 'success':'error'">
                {{ item.active ? "Aktiv":"Inaktiv" }}
              </v-chip>
            </template>
           
            <template slot="item.actions" slot-scope="{ item }">
              <div style="text-align: right;">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  small
                  @click="askDeleteItem(item)"
                  >mdi-delete</v-icon
                >
                
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dlgDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Template löschen?</v-card-title>
        <v-card-text>Soll das Template wirklich gelöscht werden?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" outlined text @click="dlgDelete = false"
            >Abbrechen</v-btn
          >
          <v-btn dark color="red darken-1" @click="deleteItem">
            <v-icon>mdi-delete</v-icon>Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  name: "PDFTemplateList",
  data: () => ({
    loading: false,
    search: "",
    dlgDelete: false,
    selectedItem: false,
    headers: [
      { text: "Status", value: "active", width: "110px", },
      { text: "Type", value: "type", width: "200px",},
      { text: "Name", value: "name" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        width: "110px",
        align: "right"
      }
    ]
  }),
  components: {  },
  methods: {
   
   itemType(type){
     if(type === "bill") return "Rechnung"
     else if(type === "confirmation") return "Auftragsbestätigung"
     else if(type === "delivery") return "Lieferschein"
     else if(type === "offer") return "Angebot"
   },
    askDeleteItem(item) {
      this.dlgDelete = true;
      this.selectedItem = item;
    },
    editItem(item) {
      this.$router.push({ name: "pdftemplate-edit", params: { id: item._id } });
    },
    
    deleteItem() {
      this.dlgDelete = false;
      this.$store
        .dispatch("pdfTemplate/deleteTemplate", this.selectedItem)
        .then((result) => {
          if (result) {
            this.$notify({
              group: "dataline",
              type: "success",
              title: "PDF Template gelöscht",
              text: "Template " + this.selectedItem.name + " wurde gelöscht"
            });

            this.selectedItem = null;
          }
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: "Fehler beim löschen des Templates. " + error.message
            });
          }
        });
    },
  

    loadItems(){
      this.loading = true;
      this.$store
      .dispatch("pdfTemplate/fetchTemplateList")
      .then(() => {
        
      })
      .catch((error) => {
        if (error.response.status != 401) {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler",
            text: error.message
          });
        }
      })
      .finally(() => {
        this.loading = false;
      });
    }
   
  },

  computed: {
    itemList() {
      return this.$store.getters["pdfTemplate/getTemplateList"];
    }
  },

  created() {
    this.loadItems();
  }
};
</script>

<style scoped>
#listcard {
  margin: 20px;
}
</style>
